<template>
    <v-container>
        <v-row
        justify="center"
        >
            <v-col
            cols="12"
            md="5"
            >
                <v-card>
                    <v-card-title>
                        Recuperación de contraseña
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                            v-model="$v.email.$model"
                            label="Escribe tu correo electrónico"
                            :error-messages="usernameErrors"
                            >
                            </v-text-field>

                            <div
                            class="d-flex justify-center"
                            >
                                <div>
                                    <v-btn
                                    color="primary"
                                    @click="submit"
                                    >
                                        Continuar
                                    </v-btn>
                                </div>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <ErrorHandler
        :notify="error"
        />
    </v-container>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required,email } from 'vuelidate/lib/validators'
import ErrorHandler from '@/components/ErrorHandler.vue'

export default {
    mixins: [validationMixin],
    data() {
        return {
            email: '',
            error: {error: ''}
        }
    },
    validations: {
        email: {required,email}
    },
    computed: {
        usernameErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('Campo requerido')
            !this.$v.email.email && errors.push('Ingrese un correo electrónico valido')
            return errors
        },
    },
    methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        submit() {
            this.$v.$touch()

            if(!this.$v.$invalid){
                this.setLoading(true)

                this.axios.post('/users/forgotpassword',{
                    email: this.email
                })
                .then((response) => {
					this.setLoading(false)

                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})

                    this.$router.push({ name: 'Login' })
				})
				.catch((error) => {
					this.setLoading(false)
					
                    this.error = {  'show':true,
                                    'color':'warning',
                                    'error': error,
                                    'time':5000
                                }
				})
            }
        }
    },
    created(){
        this.setLayout('login-layout')
    },
    components: {
        ErrorHandler
    }
}
</script>
