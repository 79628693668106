<template lang="html">
    <v-snackbar
    top
    :timeout="notify.time"
    :color="notify.color"
    v-model="notify.show"
    >
        {{message(notify.error)}}
        <template v-slot:action="{ attrs }">
            <v-btn
			color="white"
			text
			v-bind="attrs"
			@click="notify.show = false"
			v-show="notify.showClose"
            >
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>

export default {
    name: 'ErrorHandler',
    props: ['notify'],
    methods: {
        message(error) {
            let message = ""

            if(error != ''){
                if (error.status == 422) {
                    let keys = Object.keys(error.data.errors)

                    keys.filter((key) => {
                        message = error.data.errors[key][0] + '\n'
                    })
                } else if(error.status == 500) {
                    message = "Hemos tenido un error inesperado. Intenta de nuevo o comunicate con tu asesor."
                } else {
                    message = error.data.message
                }
            }

            return message
        }
    }
}
</script>
